import { useState } from "react"

//Components
import SEO from "components/seo"
import Layout from "components/layout-checkout"
import { Image, Button } from "components/anti"

//Assets
import NotFoundImage from "assets/img/404/404.png"

const NotFoundPage = () => {
  const [loading, setLoading] = useState(null)

  return (
    <Layout>
      <SEO title="404 Not Found" />
      <section className="not-found">
        <div className="container">
          <div className="not-found-wrapper">
            <img src={NotFoundImage} className="img-fluid img-not-found" />
            <p className="text p-0 m-0">Looks like you’re lost.</p>
            <p className="text p-0">
              The page you are looking for is not available.
            </p>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <div onClick={() => setLoading(true)}>
                <Button
                  link="/"
                  variant="outline-primary"
                  className={`${loading ? "loading" : ""}`}
                >
                  Back to Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
// import { Cover, Button } from "components/anti";
// import Layout from "components/layout";
// import React, { useState, useEffect } from "react";
// import Helmet from "react-helmet";
// import Seo from "components/seo";

// // assets
// import bgImg from "assets/img/common/404.jpg";
// import bg1 from "assets/img/404/not-found-1.jpg";
// import bg2 from "assets/img/404/not-found-2.jpg";
// import bg3 from "assets/img/404/not-found-3.jpg";

// const NotFoundPage = () => {
//   const [currentImg, setCurrentImg] = useState(bg1);

//   useEffect(() => {
//     const time = currentImg === bg1 ? 1500 : 150;
//     const changeImg = setInterval(() => {
//       if (currentImg === bg1) {
//         setCurrentImg(bg2);
//       } else if (currentImg === bg2) {
//         setCurrentImg(bg3);
//       } else {
//         setCurrentImg(bg1);
//       }
//     }, time);
//     return () => {
//       clearInterval(changeImg);
//     };
//   }, [currentImg]);
//   return (
//     <>
//       <Seo title="404 Not Found" />
//       <Helmet>
//         <body className="bd-not-found" />
//       </Helmet>
//       <Layout>
//         {/* <h1>Not found</h1> */}
//         <Cover
//           variant="responsive"
//           img={currentImg}
//           title="Oh no! Page not found."
//           titleClassName=""
//           text="We looked everywhere, but... Sorry, it seems like the page you’re looking for is no longer exist."
//           textClassName="py-4 w-lg-100"
//           imgHeight="h-450px h-lg-600px"
//           className="sc-404-notFound"
//           contentMaxWidth="w-lg-500px"
//         >
//           <Button variant="white" className="w-250px" link="/">
//             Back to Home
//           </Button>
//         </Cover>
//       </Layout>
//     </>
//   );
// };

// export default NotFoundPage;
