import React, { useEffect, useLayoutEffect, useContext } from "react"
// import { navigate } from "gatsby"
import { useRouter } from "next/router"

import { Navbar, Footer, Loader } from "components/anti"
import NavbarWrapper from "./navbar-wrapper"
// import { LoadingContext } from "context/loading-context"

const Layout = ({ children, withBg, withoutLink, ...props }) => {
  const router = useRouter()

  const navigate = (to) => {
    router.push(to)
  }

  // Loader Context
  // allows loader only shown when directly visited via URL
  // const { initialLoading } = useContext(LoadingContext)

  // Mobile viewport height workaround
  // prevent quirky behaviour on mobile when cover is set to 100vh
  const changeHeight = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }
  useEffect(() => {
    changeHeight()
    window.addEventListener("resize", changeHeight)
    return () => window.removeEventListener("resize", changeHeight)
  }, [])

  // Transition link
  // for smooth transition effect
  useEffect(() => {
    const wrapper = document.querySelector("main")
    wrapper.classList.remove("fadeOut")
    wrapper.classList.add("animated", "fadeIn")
  }, [router])
  useEffect(() => {
    const wrapper = document.querySelector("main")
    const anchor = document.querySelectorAll("a")
    anchor.forEach((item) => {
      const navigation = (e) => {
        const redirect = item.getAttribute("href")
        const currentLoc = window.location.pathname
        e.preventDefault()
        if (currentLoc !== redirect) {
          wrapper.classList.remove("fadeIn")
          wrapper.classList.add("fadeOut")
          setTimeout(() => {
            navigate(redirect)
          }, 250)
        }
      }
      if (item.getAttribute("href") && !item.getAttribute("target")) {
        item.addEventListener("click", navigation)
      }
    })
    return () => {
      anchor.forEach((item) => {
        const navigation = (e) => {
          const redirect = item.getAttribute("href")
          const currentLoc = window.location.pathname
          e.preventDefault()
          if (currentLoc !== redirect) {
            wrapper.classList.remove("fadeIn")
            wrapper.classList.add("fadeOut")
            setTimeout(() => {
              navigate(redirect)
            }, 250)
          }
        }
        if (item.getAttribute("href") && !item.getAttribute("target")) {
          item.removeEventListener("click", navigation)
        }
      })
    }
  }, [])

  return (
    <>
      {/* <Loader variant="image" theme="dark" effect="fadeOut" /> */}

      {/* withBg props between light || dark */}
      <NavbarWrapper {...props} withBg={withBg} withoutLink={withoutLink} />
      {/* <Navbar withBg={withBg} withoutLink={withoutLink} /> */}
      <main>
        {/* {!initialLoading ? children : <div className="h-vh-100 w-vw-100" />} */}
        {children}
        <Footer type={props?.footerType ?? 2} />
      </main>
    </>
  )
}

export default Layout
